import {React, useEffect} from 'react'
import Simulator from '../pages/Simulator/Simulator'
import Header from '../components/Header/Header'
import { t } from "@lingui/macro";

const SimulatorRouter = ({ refs, handleScrollTo }) =>
{

    const title = t`Simule su campo`;
    const description = t`A continuación, describa las principales características de su campo, para hacer una estimación del funcionamiento de nuestra garantía de rinde.`;
        useEffect(() =>
	{
	    document.title = t`Yieldy - Pruebe el simulador de garantía de rinde`;
    }, [title])
    return (
        <div>
            <Header title={title} description={description} />
            <Simulator refs={refs} handleScrollTo={handleScrollTo} />
        </div>
    )
}

export default SimulatorRouter
