import {React, useEffect} from 'react'
import We from '../pages/We/We'
import Header from '../components/Header/Header'
import { t } from "@lingui/macro";

const WeRouter = ({ refs, handleScrollTo }) =>
{

    const title = t`Nosotros`
    const description = t`A continuación, conozca al equipo que nos integra`;
    useEffect(() =>
	{
	    document.title = t`Yieldy - Quiénes somos`
    }, [title])
    return (
        <div>
            <Header title={title} description={description} />
            <We refs={refs} handleScrollTo={handleScrollTo} />
        </div>
    )
}

export default WeRouter
