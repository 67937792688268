import { useRefs } from '../../context/RefContext'
import './We.scss'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import fma from '../../images/adv_maa.jpg';
import fgg from '../../images/adv_gg.jpg';
import ffl from '../../images/founder_fl.jpg';
import fgs from '../../images/founder_gsb.jpg';
import flg from '../../images/adv_lg.jpg';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
/*import './Footer.scss'*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from "@lingui/macro";
const We = () =>
{
    const { weRef } = useRefs()
    useEffect(() =>
	{
            Aos.init({ duration: 1000, once: true, easing: 'ease-in-out' });
    }, [])
    return (
            <div ref={weRef} className='people'>
            <div className='people_container'>
            <h2 data-aos='fade-left'><Trans>Board</Trans></h2>
            <div className="cards_container">

	    <div className="card" data-aos='fade-up' data-aos-delay='100'>
            <div className="card_title" >
	    	    <h4><img src={fgs} alt="face of founder gsb"></img></h4>
            <h4>Gonzalo Sánchez Barrio</h4>
	    <h3>Co-Founder &amp; CEO</h3>
	    <hicon>
	    <div className="icon">
            <a href="https://www.linkedin.com/in/gonzalosanchezbarrio/" style={{color: "inherit"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
            </div>
	    &nbsp; 
	    <div className="icon">
            <a href="mailto:gonzalo@yieldy.ai" style={{color: "inherit"}}><FontAwesomeIcon icon={faEnvelope} /></a>
            </div>
	    </hicon>
                        </div>
            {/*<div className="card_bottom">
                            <div className="text_container">
                                <p className='open_sans'>Founder 4 es..</p>
				</div>
                            </div>*/}
            </div>
	
            <div className="card" data-aos='fade-up' data-aos-delay='200'>
            <div className="card_title" >
	    	    <h4><img src={ffl} alt="face of founder 3"></img></h4>
            <h4>Federico Lamagna</h4>
	    <h3>Co-Founder &amp; CTO</h3>
	    <hicon>
	    <div className="icon">
            <a href="https://www.linkedin.com/in/federico-lamagna/" style={{color: "inherit"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
            </div>
	    &nbsp; 
	    <div className="icon">
            <a href="mailto:flamagna@yieldy.ai" style={{color: "inherit"}}><FontAwesomeIcon icon={faEnvelope} /></a>
            </div>
	    </hicon>
                        </div>
            {/*<div className="card_bottom">
                            <div className="text_container">
                                <p className='open_sans'>Founder 3 es..</p>
                            </div>
                            </div>*/}
            </div>

            </div>
	    </div>
	    <div className='people_container'>
            <h2 data-aos='fade-left'><Trans>Advisors</Trans></h2>
	                <div className="cards_container">
            <div className="card" data-aos='fade-up' data-aos-delay='150'>
            <div className="card_title">
	    <h4><img src={fma} alt="face of Advisor Matias Amuchastegui"></img></h4>
            <h4>Matías Álvarez Amuchastegui</h4>
	    <h3>  </h3>
	    <hicon>
	    <div className="icon">
            <a href="https://www.linkedin.com/in/matias-alvarez-amuchástegui-0523244/" style={{color: "inherit"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
            </div>
	    </hicon>
            </div>
	    {/*            <div className="card_bottom">
            <div className="text_container" >
            <p className='open_sans'>Founder 1 es..</p>
                            </div>
                        </div>*/}
	</div>
	                <div className="card" data-aos='fade-up' data-aos-delay='300'>
            <div className="card_title">
	    <h4><img src={flg} alt="face of advisor Luc Goutermanoff"></img></h4>
            <h4>Luc Goutermanoff</h4>
	    <hicon>
	    <div className="icon">
            <a href="https://www.linkedin.com/in/luc-goutermanoff/" style={{color: "inherit"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
            </div>
	    </hicon>
                        </div>
            {/*<div className="card_bottom">
                            <div className="text_container">
                                <p className='open_sans'>Founder 2 es..</p>
                            </div>
            </div>*/}
            </div>
	                <div className="card" data-aos='fade-up' data-aos-delay='450'>
            <div className="card_title">
	    <h4><img src={fgg} alt="face of advisor Gonzalo Guastavino"></img></h4>
            <h4>Gonzalo Guastavino</h4>
	    <hicon>
	    <div className="icon">
            <a href="https://www.linkedin.com/in/gonzalo-guastavino/" style={{color: "inherit"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
            </div>
	    </hicon>
                        </div>
            {/*<div className="card_bottom">
                            <div className="text_container">
                                <p className='open_sans'>Founder 2 es..</p>
                            </div>
            </div>*/}
            </div>
	    </div>
	    </div>
            </div>
    )
}

export default We
