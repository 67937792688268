import React from "react"
import { useLingui } from "@lingui/react"
import { dynamicActivate } from "./App.js";
import './App.scss';
import './components/ResponsiveNavbar/ResponsiveNavbar.scss';

const wholeFunction = async (langcode) => {
    await dynamicActivate(langcode);
}

const LanguageSelector = () => {
    const { i18n } = useLingui();
    return (<div style={{justifyContent : "center"}}>
	    <button 
                onClick={() => {wholeFunction('en')}}
                disabled={i18n.locale === 'en'}>
                <lib>EN</lib>
            </button>
	    <button
          onClick={() => {wholeFunction('es')}}
                disabled={i18n.locale === 'es'}>
                <lib>ES</lib>
	    </button>
	    <button
                onClick={() => {wholeFunction('pt')}}
                disabled={i18n.locale === 'pt'}>
                <lib>PT</lib>
	    </button>
	<button
		onClick={() => {wholeFunction('it')}}
	    disabled={i18n.locale === 'it'}>
		<lib>IT</lib>
	</button>
		<button
		    onClick={() => {wholeFunction('de')}}
		    disabled={i18n.locale === 'de'}>
		    <lib>DE</lib>
		</button>
		</div>
    )
};
export default LanguageSelector
