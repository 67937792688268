import AppRouter from "./router/AppRouter";
import './App.scss'
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import React, { useEffect } from 'react';
//import { messages as enMessages } from "./locales/en/messages";
//import { messages as esMessages } from "./locales/es/messages";
import { detect, fromUrl, fromStorage, fromNavigator } from "@lingui/detect-locale";


export const locales = {
    en: "English",
    es: "Español",
    pt: "Português",
    it: "Italiano",
    de: "Deutsch"
};

export const defaultLocale = "es";

const LOCAL_STORAGE_KEY = 'lang';

// const isLocaleValid = (locale: string | null) => `${locale}` in locales; // esta es typescript y falla en lingui extract, posiblemente porque ".js"

const isLocaleValid = (locale) => `${locale}` in locales;

export function getLocale() {
  const detectedLocale = detect(
    fromUrl("lang"),
    fromStorage(LOCAL_STORAGE_KEY),
    fromNavigator(),
    () => defaultLocale,
  );
    var detecc=detectedLocale.slice(0,2);
    console.log(detecc)

  return isLocaleValid(detecc) ? detecc : defaultLocale;
}

export async function dynamicActivate(locale) {
    const { messages } = await import(`./locales/${locale}/messages`);
    i18n.load(locale, messages);
    i18n.activate(locale);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, locale);
}



//i18n.load({"es": esMessages, "en": enMessages});
//i18n.activate(gotten);

function App()
{
    useEffect(() => {
            // With this method we dynamically load the catalogs
        dynamicActivate(getLocale());
    }, []);

    return (
	<I18nProvider i18n={i18n}>
	    <div className="App">
      <AppRouter />
	    </div>
	      </I18nProvider>
  );
}

export default App;
