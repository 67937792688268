import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Subscribe.scss'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { t, Trans } from "@lingui/macro";
const Subscribe = () =>
{
    useEffect(() =>
    {
        Aos.init({ duration: 1000, once: true, easing: 'ease-in-out' });
    }, [])
    const form = useRef();
    const sendEmail = (e) =>
    {
        e.preventDefault();

        emailjs.sendForm('service_wik8fhe', 'template_kg45gge', form.current, '9blp-0zXta833kWex')
            .then((result) =>
            {
                form.current.reset(); // Reset the form fields
                Swal.fire({
                    title: t`¡Te suscribiste!`,
                    text: t`¡Tu suscripción ha sido enviada con éxito!`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    timer: 1500
                });
            }, (error) =>
            {
                console.log(error.text);
            });
    };
    return (
        <div className='subscribe'>
            <div className="subscribe_cover">
                <div className="subscribe_container">
                    <div className="icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <p className='open_sans'><Trans>¡Suscribite para recibir información actualizada del proyecto!</Trans></p>
                    <form data-aos='fade-up' ref={form} action='submit' className="input" onSubmit={sendEmail}>
                        <input type="text" className="input open_sans" placeholder={t`Tu email...`} name='user_email' />
                        <button className='open_sans'><Trans>SUSCRIBIRSE</Trans></button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Subscribe
