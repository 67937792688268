import React, { useState, useEffect } from 'react';
import { useResult } from '../../context/ResultContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Survey.scss';
import { t, Trans } from "@lingui/macro";
const Survey = () =>
{
    const { generateSurveyData, simulatorData, primaResult } = useResult();
    const [adquiere, setAdquiere] = useState('');
    const [porque, setPorque] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [errors, setErrors] = useState({ adquiere: '', comentarios: '', porque: '', otroMotivo: '' });
    const [otroMotivo, setOtroMotivo] = useState('');

    const navigate = useNavigate();
    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        const newErrors = {
            adquiere: !adquiere ? t`Por favor, seleccione una opción` : '',
            comentarios: !comentarios ? t`Por favor, ingrese sus comentarios` : '',
            porque: (adquiere === 'no' || adquiere === 'quizas') && !porque ? t`Por favor, seleccione una opción` : '',
            otroMotivo: porque === 'otro' && !otroMotivo ? t`Por favor, especifique` : ''
        };
        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error);
        if (!hasErrors)
        {
            try
            {
                await generateSurveyData(adquiere, comentarios, porque, otroMotivo);
                Swal.fire({
                    icon: 'success',
                    title: t`¡Gracias por usar nuestro simulador!`,
                    showConfirmButton: true,
                    confirmButtonText: t`Volver al simulador`,
                    timer: 1500
                }).then(() =>
                {
                    navigate('/simulator');
                });
            } catch
            {
                Swal.fire({
                    icon: 'error',
                    title: t`Hubo un error, por favor intente de nuevo.`,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
	document.getElementById('top')?.scrollIntoView()
    }, [])
    return (
        <div className='survey' id='top'>
            <div className="survey_container">
                <div className="subtitle">
                    <h4><Trans>Conozca el impacto de nuestra garantía en su producción agrícola.</Trans></h4>
                    <h4><Trans>¡Gracias por usar nuestro simulador de garantía de rendimiento!</Trans></h4>
                </div>
                <div className="text_container">
                    <div className="results">
                        <h4><Trans>Resultados</Trans></h4>
                        <ul>
                            <li className='open_sans'><Trans>Rendimiento Garantizado:</Trans> {Number(simulatorData.rendimientoAGarantizar).toLocaleString('es-ES')} kg/ha</li>
                            <li className='open_sans'><Trans>Cantidad de hectareas:</Trans> {Number(simulatorData.tamañoLotes).toLocaleString('es-ES')}</li>
                            <li className='open_sans'><Trans>Rendimiento Esperado:</Trans> {Number(simulatorData.rendimientoEsperado).toLocaleString('es-ES')} kg/ha</li>
                            <li className='open_sans'><Trans>Tasa de la Garantía:</Trans> {(primaResult * 100).toFixed(2)}%</li>
                            <li className='open_sans'><Trans>Costo por hectárea:</Trans> {(simulatorData.rendimientoEsperado * primaResult).toFixed(2)} kg/ha</li>
                        </ul>
                    </div>
                </div>
		<div className="subtitle">
                    <p><b><Trans>Nota Importante</Trans></b>: <Trans>El simulador que estás probando es solo una versión de prueba. El rendimiento esperado se calculará automáticamente una vez que subas el archivo georreferenciado de tu campo. La tasa se basará en esta estimación. El costo por hectárea que figura será en función del rendimiento real, en este caso y con fines de simulación estamos suponiendo que será el rendimiento esperado.</Trans></p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form_item">
                        <p><Trans>¿Adquirirías este producto?</Trans></p>
                        <select className='open_sans' name="adquirir" id="adquirir" value={adquiere} onChange={(e) => setAdquiere(e.target.value)}>
                            <option className='open_sans' value=""><Trans>Seleccione una opción</Trans></option>
                            <option className='open_sans' value="si"><Trans>Sí</Trans></option>
                            <option className='open_sans' value="no"><Trans>No</Trans></option>
                            <option className='open_sans' value="quizas"><Trans>Quizás</Trans></option>
                        </select>
                        {errors.adquiere && <span className="error open_sans">{errors.adquiere}</span>}
                    </div>
                    {
                        (adquiere === 'no' || adquiere === 'quizas') && (
                            <div className="form_item">
                                <p><Trans>Si respondió "No" o "Quizás", por favor indíquenos qué factores influyen en su decisión:</Trans></p>
                                <select className='open_sans' name="porque" id="porque" value={porque} onChange={(e) => setPorque(e.target.value)}>
                                    <option className='open_sans' value=""><Trans>Seleccione una opción</Trans></option>
                                    <option className='open_sans' value="costoAlto"><Trans>El costo es demasiado alto</Trans></option>
                                    <option className='open_sans' value="noEntiendo"><Trans>No entiendo completamente cómo funciona</Trans></option>
                                    <option className='open_sans' value="noNecesito"><Trans>No creo que necesite este tipo de garantía</Trans></option>
                                    <option className='open_sans' value="otrasFormasDePago"><Trans>Prefiero otras formas de gestionar el riesgo</Trans></option>
                                    <option className='open_sans' value="otro"><Trans>Otro</Trans></option>
                                </select>
                                {errors.porque && <span className="error open_sans">{errors.porque}</span>}
                                {
                                    porque === 'otro' && (
                                        <input className='open_sans'
                                            type="text"
                                            name="otro"
                                            id="otro"
                                            value={otroMotivo}
                                            onChange={(e) => setOtroMotivo(e.target.value)}
                                               placeholder={t`Por favor especifique...`}
                                        />
                                    )
                                }
                                {errors.otroMotivo && <span className="error open_sans">{errors.otroMotivo}</span>}
                            </div>
                        )
                    }
                    <div className="form_item">
                        <p><Trans>Comentarios</Trans></p>
                        <textarea className='open_sans' rows="10" placeholder={t`¿Tiene algún comentario o sugerencia adicional?`} value={comentarios} onChange={(e) => setComentarios(e.target.value)} />
                        {errors.comentarios && <span className="error open_sans">{errors.comentarios}</span>}
                    </div>
                    <button type="submit" className='open_sans'><Trans>Enviar Encuesta</Trans></button>
                    <button type="button" className='open_sans' onClick={() => navigate('/simulator')}><Trans>Volver al Simulador</Trans></button>
                </form>
            </div>
        </div>
    );
};

export default Survey;

