import './Partners.scss'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import lsima from '../../images/logosima.png';
import lharvest from '../../images/harvestround.png';
import lhelm from '../../images/helmag.png';
import lsmartway from '../../images/smartway.png';
import { Trans } from "@lingui/macro";
const Partners = () =>
{
    useEffect(() =>
    {
        Aos.init({ duration: 1000, once: true, easing: 'ease-in-out' });
    }, [])
    return (
          <div className='partners'>
           <div className='partners_container'>
            <h2 data-aos='fade-left'><Trans>Alianzas</Trans></h2>
            <div className="cards_container">
             <div className="card">
	     <h4><a href="https://sima.ag/"><img src={lsima} alt="sima logo"></img></a></h4>
             </div>
             <div className="card">
	      <h4><a href="https://nasaharvest.org/"><img src={lharvest} alt="nasa harvest logo"></img></a></h4>
	     </div>
	     <div className="card">
	      <h4><a href="https://www.helmag.com/"><img src={lhelm} alt="helm ag logo"></img></a></h4>
	     </div>
	     <div className="card">
	      <h4><a href="https://www.onsmartway.com/"><img src={lsmartway} alt="smartway logo"></img></a></h4>
	     </div>
	    </div>
           </div>
	  </div>
    )
}

export default Partners
