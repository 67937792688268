import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import './Footer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sx from '../../images/favicon/favicon-32x32.png';

const Footer = () =>
{
    return (
            <div className='footer'>
            <div className="footer_container">
                <div className="icon">
            <a href="https://www.linkedin.com/company/yieldy-ai/" style={{color: "inherit"}}><FontAwesomeIcon icon={faLinkedinIn} /></a>
                </div>
            <div className="text_container">
            <p className='open_sans'>
	    <img src={sx} alt="yd" style={{verticalAlign: "middle"}}></img> Copyright 2024 - Yieldy&reg;
	    {/*-
            <a href="https://yieldy.ai" style={{color: "inherit", textDecoration: "none"}}><span className='open_sans'> yieldy.ai</span></a>*/}
	     </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
