import { useRefs } from '../../context/RefContext'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.scss'
import Swal from 'sweetalert2';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { t, Trans} from "@lingui/macro";
const Contact = () =>
{
    useEffect(() =>
    {
        Aos.init({ duration: 1000, once: true, easing: 'ease-in-out' });
    }, [])
    const form = useRef();

    const sendEmail = (e) =>
    {
        e.preventDefault();

        emailjs.sendForm('service_wik8fhe', 'template_hrqkuao', form.current, '9blp-0zXta833kWex')
            .then((result) =>
            {
                form.current.reset(); // Reset the form fields
                Swal.fire({
                    title: t`Mensaje Enviado`,
                    text: t`¡Tu mensaje ha sido enviado con éxito!`,
                    icon: 'success',
                    confirmButtonText: 'OK', timer: 1500
                });
            }, (error) =>
            {
                console.log(error.text);
            });
    };

    const { contactRef } = useRefs()
    return (
        <div ref={contactRef} className='contact'>
            <div className="contact_container" data-aos='fade-up'>
                <div className="left">
                    <form ref={form} action="submit" onSubmit={sendEmail}>
                        <input type="text" className="input open_sans" placeholder={t`Nombre`} name='user_name' />
                        <input type="text" className="input open_sans" placeholder={t`Email`} name='user_email' />
			<textarea name="message" className='open_sans' placeholder={t`Mensaje`} ></textarea>
                        <button><Trans>Enviar</Trans></button>
                    </form>
                </div>
                <div className="right">
                    <div className="text_container">
                        <h2><Trans>Contactanos!</Trans></h2>
                        <span className='open_sans'>Blanco Encalada 197, San Isidro, Buenos Aires, Argentina</span>
                        <p className='open_sans'><a style={{color: "inherit"}} href="mailto:info@yieldy.ai">info@yieldy.ai</a></p>
			{/*<p className='open_sans'>https://yieldy.ai</p>*/}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact

