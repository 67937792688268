import {React, useEffect} from 'react';
import Project from '../pages/Project/Project';
import HowItWorks from '../pages/HowItWorks/HowItWorks';
import Platform from '../pages/Platform/Platform';
import Benefits from '../pages/Benefits/Benefits';
import Contact from '../pages/Contact/Contact';
import Subscribe from '../pages/Subscribe/Subscribe';
import Partners from '../pages/Partners/Partners';
import Header from '../components/Header/Header';
import { t } from "@lingui/macro";

const LandingRouter = () =>
{
    const title = t`Garantía de Rendimiento`;
    const description = t`Enfoque innovador que combina datos georreferenciados, aprendizaje automático e imágenes satelitales para brindarte la precisión y seguridad que necesitas en cada temporada agrícola. Con Yieldy, maximiza tus rendimientos y minimiza los riesgos.`;
    const button = t`Comenzar simulación`;
        useEffect(() =>
	{
	    document.title = t`Yieldy - La revolucionaria garantía de rinde`
    }, [title])
    return (
	
        <div>
            <Header title={title} description={description} button={button} />
            <div >
                <Project />
            </div>
            <div >
                <HowItWorks />
            </div>
            <div >
                <Platform />
            </div>
            <div >
                <Benefits />
            </div>
            <div  >
		<Partners />
            </div>
	    <div >
		<Contact />
            </div>
            <div >
                <Subscribe />
            </div>
        </div>
    );
};

export default LandingRouter;

