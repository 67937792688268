import { sqrt, exp, pow, erf } from 'mathjs';

// v_n+2 (4-ago-24 F.L. -- Incluye una ajuste lineal entre trigger < 0.9*rinde_esperado y rinde_esperado, para que sea suave la transición hacia tasa = 1 en trigger=esperado
// hace falta el cast explicito parseFloat, venían trayendo problemas ciertas combinaciones para la evaluación de las condiciones)

function prima(rend_esp, rend_ind)
{
    rend_esp = parseFloat(rend_esp);
    rend_ind = parseFloat(rend_ind);
    var mu = rend_esp;
    var markup = 0.01;
    var tasa;
    if (rend_ind >= rend_esp) {
	tasa = 1;
    } else {
	var incertidumbre = (0.45-0.01*(rend_esp)/1000);
	var sigma = rend_esp * incertidumbre;
	var pdfv = 1 / (sqrt(2 * Math.PI) * sigma) * exp(-(pow(rend_ind - mu, 2) / (2 * pow(sigma, 2))));
	var cdfv = 1 / 2 * (1 + erf(((rend_ind - mu) / sigma) / sqrt(2)))
	var prima = (rend_ind - mu) * cdfv + sigma * sigma * pdfv;
	tasa = prima / rend_esp + markup;
	if (rend_ind > 0.9*rend_esp) {
	    tasa = tasa +(1-tasa)/(0.1*rend_esp)*(rend_ind-0.9*rend_esp);
	}

    }
    return tasa;
}
export default prima

