import React from 'react'
import lt from '../../images/lefttop.png'
import lm from '../../images/leftmiddle.png'
import lb from '../../images/leftbottom.png'
import rt from '../../images/righttop.png'
import rm from '../../images/rightmiddle.png'
import rb from '../../images/rightbottom.png'
import bs from '../../images/feature_base.png'
import ylg from '../../images/yieldy_logo_vert_k.png'
import './Project.scss'
import { useRefs } from '../../context/RefContext'
import { Link } from 'react-router-dom'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Trans } from "@lingui/macro";


const Project = () =>
{
    useEffect(() =>
    {
        Aos.init({ duration: 1000, once: true, easing: 'ease-in-out' });
    }, []);

    const { projectRef } = useRefs()

    return (
        <div ref={projectRef} className='project'>
            <div className="project_container">
                <div className="left">
		    <div class="parent unselectable">
                        <div class="subparent">
			    <div class="treble">
				<div class="element lefttop" data-aos='fade-right'>
				    <div class="icon">
					<img src={lt} alt="cost"></img>
				    </div>
				    <div class="icon_name lefttopcol"><Trans>MENOR COSTO</Trans></div>
				</div>
				<div class="element leftmiddle" data-aos='fade-right'>
				    <div class="icon">
					<img src={lm} alt="platform"></img>
				    </div><div class="icon_name leftmiddlecol"><Trans>PLATAFORMA</Trans></div>
				</div>
				<div class="element leftbottom" data-aos='fade-right'>
				    <div class="icon">
					<img src={lb} alt="farm"></img>
				    </div>
				    <div class="icon_name leftmiddlecol"><Trans>TU CAMPO</Trans></div>
				</div>
			    </div>
			    <div class="treble">
				<div class="element righttop" data-aos='fade-left'>
				    <div class="icon">
					<img src={rt} alt="georeference"></img>
				    </div>
				    <div class="icon_name righttopcol"><Trans>GEORREFERENCIACIÓN</Trans></div>
				</div>
				<div class="element rightmiddle" data-aos='fade-left'>
				    <div class="icon"><img src={rm} alt="data analysis"></img>
				    </div>
				    <div class="icon_name rightmiddlecol"><Trans>ANÁLISIS DE DATOS</Trans></div>
				</div>
				<div class="element rightbottom" data-aos='fade-left'>
				    <div class="icon">
					<img src={rb} alt="guarantee"></img>
				    </div>
				    <div class="icon_name rightbottomcol"><Trans>GARANTÍA</Trans></div>
				</div>
			    </div>
			</div>
			<div class="subparent_bot">
			    <div id="bracket" class="base" data-aos='fade-up'><img src={bs} alt="bracket"></img></div>
			    <div class="logobase" data-aos='fade-up'> 
				<div class="logoim">
				    <img src={ylg} alt="yieldy"></img>
				</div>
				<div class="slogan"><Trans>GARANTÍA DE RENDIMIENTO</Trans>
				</div>
			    </div>
			</div>
                    </div>
		</div>
                <div className="right" data-aos='fade-left'>
                    <h2><Trans>Transformando el Riesgo Agrícola con Tecnología Innovadora</Trans></h2>
                    <p className='open_sans'><Trans>Yieldy es una plataforma innovadora que transforma la gestión del riesgo agrícola. Utilizando datos georreferenciados, aprendizaje automático e imágenes satelitales, Yieldy ofrece precisión y seguridad para maximizar rendimientos y minimizar riesgos. Garantiza un rendimiento de indiferencia, brindando tranquilidad y estabilidad económica a los agricultores.</Trans></p>
                    <button className='open_sans'>
                        <Link
                            to='/simulator'
                            className='open_sans'
                        >
                            <Trans>Comenzar simulación</Trans>
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Project
