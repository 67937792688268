import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDqeSTV9ME83vXyQqVfl5vSJlknXXvTI5Y",
  authDomain: "yieldy-6fd27.firebaseapp.com",
  databaseURL: "https://yieldy-6fd27-default-rtdb.firebaseio.com",
  projectId: "yieldy-6fd27",
  storageBucket: "yieldy-6fd27.appspot.com",
  messagingSenderId: "114736842686",
  appId: "1:114736842686:web:b317c10c702778d722dbef",
  measurementId: "G-EDCB2VG8DN"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
